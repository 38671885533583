import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { GetStaticProps } from 'next';
import axios from 'axios';
import Cookie from 'js-cookie';
import dayjs from 'dayjs';
import { useDebounce } from 'react-use';
import isToday from 'dayjs/plugin/isToday';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import Lottie from 'lottie-web';
import { trace, traceEvent } from '@wk/wk-gatherer';
import { useRouter } from 'next/router';
import {
  getCurTimezone,
  canViewClassData,
  getClassInLink,
  getHomeData,
  getTalkCloudLink,
  getRecentlyCourseData,
  TeachingMaterialsParams,
  getTeachingMaterialsInfo,
  TeachingMaterialsRes,
  getVisitorData,
  getMyCourse,
  getExpectationsGS2,
} from '@/api/home';
import { updateProfile } from '@/api/profile';
import { Button, Mask, Modal, Toast } from '@/components';

import Layout from '@/pageComponents/Layout';
import { ClassTypeEnum, SubjectTypeEnum, IExpectationsGS2Teacher } from '@/pageComponents/Home/interface';
import {
  TrialCourse,
  Subscribe,
  Lecture,
  Notice,
  WrongQuestion,
  NewHomework,
  LessonTest,
  SubscriptionCourse,
  MyLecture,
  SubscriptionEs,
} from '@/pageComponents/Home';
import { getClassTypeName } from '@/pageComponents/Home/util';
import { HomeDataRes, HomeTemplateProps, RecentlyCourseItem, SubjectMethodMap, ToReserveList } from '@/api/types/home';
import {
  CLASS_IN_URL,
  EDU_URL,
  LEAD_FLAG,
  OFFICIAL_WEBSITE_URL,
  OFFICIAL_WEBSITE_URL_TEST,
  SCH_URL,
  sourceMap,
  STUDENT_TIMEZONES,
  TOKEN,
} from '@/utils/constants';
import cacheStorage from '@/utils/cacheStorage';
import { AGORA_URL, HOMEWORK_URL, IS_PROD } from '@/utils/env';

import styles from './style.module.scss';
import { generateNamePrefix, removeTokenInfo } from '@/utils/helpers';
import PATHS from '@/utils/paths';
import useUser from '@/store/useUser';
import { log, uploadLog } from '@/api/log';

import 'swiper/css';
import 'swiper/css/pagination';
import { getParams, isAndroidPad, forbiddenBrowser, isTablet, isTourist, isiPad } from '@/utils/utils';
import { wakeUpWukong } from '@/utils/wakeUp';
import { ScheduleDetailRes } from '@/api/types/curriculum';
import { DEVICE } from '@/utils/enum';
import InfoModal from '@/components/InfoModal';
import { getHasUserInClass, getHomeworkList } from '@/api/curriculum';
import { removeStorage } from '@/pageComponents/DeviceDetect/utils';
import useNews from '@/store/useNews';
import { HomeworkProps } from '@/pageComponents/Home/NewHomework/tyeps';
import VerifyModalDynamic from '@/pageComponents/VerifyModal/indexDynamic';
import useModuleName from '@/store/useModule';
import toast from '@/components/Toast';
import parseJWT from '@/utils/parseJWT';
import ToolTipModal from '@/pageComponents/Curriculum/ToolTipModal';
import Expectations from '@/pageComponents/Autonomy/Expectations';
import ExpectationsGS2 from '@/pageComponents/Autonomy/ExpectationsGS2';
import ParentVerification from '@/pageComponents/Autonomy/ParentVerification';
import { LessonType } from '@/api/app';
import ParentalVerification from '@/pageComponents/Autonomy/ClassPreparation/ParentalVerification';

import { goWebSite } from '@/utils/goWebSite';
import LearnSituation from '@/pageComponents/Home/LearnSituation';
import useLearnSituation from '@/pageComponents/Home/hooks/useLearnSituation';
import HomeAbsent from '@/pageComponents/Home/HomeAbsent';
dayjs.extend(isToday);

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'home',
        'curriculum',
        'login',
        'common',
        'appointment',
        'order',
        'trailCourse',
      ])),
    },
  };
};

const prefixName = generateNamePrefix('home.');

const Home = (props: HomeTemplateProps) => {
  const { onLoading, curStudent = null, students, onGetStudents, isWrapperLoading, stuScheduleContent } = props;
  const { reserveFlag, account, isCompletedParentalVerification, setIsGs2User } = useUser();
  const { t, i18n } = useTranslation('home');
  const [lesson, setLesson] = useState<Array<RecentlyCourseItem>>([]);
  const [teachingMaterials, setTeachingMaterials] = useState<Array<TeachingMaterialsRes>>([]);
  const [homeworkData, setHomeworkData] = useState<HomeworkProps>();
  const router = useRouter();
  const { t: curriculumTranslation } = useTranslation('curriculum');
  const { t: loginTranslate } = useTranslation('login');
  const [visible, setVisible] = useState(false);
  const [mathVis, setMathVis] = useState<'NONE' | 'TRAILED' | 'COMPLETED'>('NONE');
  const [chineseVis, setChineseVis] = useState<'NONE' | 'TRAILED' | 'COMPLETED'>('NONE');
  const [englishVis, setEnglishVis] = useState<'NONE' | 'TRAILED' | 'COMPLETED'>('NONE');
  const [absentData, setAbsentData] = useState<ScheduleDetailRes>();
  const [trainShow, setTrainShow] = useState<string>('');
  const [isAppointment, setIsAppointment] = useState<boolean>(false);
  // const [accountShow, setAccountShow] = useState<boolean>(false);
  // const [howClassesData, setHowClasses] = useState<HowClasses | null>();
  const [infoModalVisible, setInfoModalVisible] = useState<boolean>(false);
  const [homeData, setHomeData] = useState<HomeDataRes | null>(null);
  const { updateNews } = useNews();
  const [showVerCode, setShowVerCode] = useState(false);
  const [throwSubject, setThrowSubject] = useState<LessonType>();
  const [recommendList, setRecommendList] = useState<HomeDataRes['recommendTips']>([]);
  const modalRef = useRef<any>();
  const [isAttendance, setIsAttendance] = useState<boolean>(false);
  const [myCourseList, setMyCourseList] = useState<ToReserveList[]>([]);
  const { updateModuleName } = useModuleName();
  const [showVerificationModal, setShowVerificationModal] = useState<boolean>(false);
  const { learnSituationData } = useLearnSituation();
  const topWrapperRef = useRef<any>(null);
  const bottomWrapperRef = useRef<any>(null);

  const urlParams = getParams() || {};
  const tourist = isTourist();
  // gs2.0 中文用户
  const [gs20ChineseUser, setGs20ChineseUser] = useState<boolean>(false);
  // gs2.0
  const [gs2, setGs2] = useState<boolean>();
  // 推荐入口老师数据
  const [expectationsGs2, setExpectationsGs2] = useState<IExpectationsGS2Teacher>();
  // 推荐入口老师头像数据
  const [expectationsTeachers, setExpectationsTeachers] = useState<string[]>([]);
  const onBeforeClick = () => {
    if (tourist) {
      setShowVerCode(true);
      return false;
    }
    return true;
  };

  const memorizedHandleShowVerCodeChange = useCallback(() => {
    setShowVerCode(false);
  }, []);

  const subscribeSize = useMemo(() => {
    const count = [englishVis, chineseVis, mathVis].filter((vis) => vis !== 'COMPLETED').length;

    if (count === 0) {
      return 'S';
    }
    if (count === 1) {
      return 'L';
    }
    if (count === 2) {
      return 'M';
    }
    return 'S';
  }, [mathVis, chineseVis, englishVis]);

  const homeView = useCallback(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { from = '', subject = '', landing_id = '' } = router?.query || {};
      if (from || subject || landing_id) {
        const data = {
          _event: 'O_PC_HomeView',
          source: sourceMap.get(from as string) || '',
          subject,
          landing_id,
        };
        traceEvent({
          data,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [router?.query]);

  useDebounce(
    () => {
      homeView();
    },
    2000,
    [],
  );
  useEffect(() => {
    if (router.query.noAuth) {
      removeTokenInfo(null, false);
      const returnUrl = encodeURIComponent(window?.location?.origin);
      window.location.href = IS_PROD
        ? `${OFFICIAL_WEBSITE_URL}?return_url=${returnUrl}`
        : `${OFFICIAL_WEBSITE_URL_TEST}?return_url=${returnUrl}`;
    }
  }, []);

  /**
   * 是否展示预习按钮
   */
  const getPreviewCourseBtnStatus = () => {
    const teachingMaterialsParams: Array<TeachingMaterialsParams> = lesson.map((item) => {
      return {
        uuid: item.uuid,
        courseScheduleId: item.courseSchedule.uuid,
        status: item.status,
        courseSectionId: item.courseSchedule.courseSectionId,
        startDateTime: item.courseSchedule.startDateTime,
        timezone: curStudent?.timezoneStd,
        endDateTime: item.courseSchedule.endDateTime,
        published: item.courseSchedule.published,
      };
    });
    if (teachingMaterialsParams && teachingMaterialsParams.length && curStudent?.timezoneStd) {
      getTeachingMaterialsInfo(teachingMaterialsParams).then((teachingMaterialsInfo) => {
        if (teachingMaterialsInfo && teachingMaterialsInfo.length) {
          setTeachingMaterials(teachingMaterialsInfo);
        }
      });
    }
  };

  /**
   * 获取主页课表数据 - 最近课程
   */
  const handleGetRecentlyCourseData = () => {
    const jwt = parseJWT();
    if (curStudent?.uuid || jwt?.data?.studentId) {
      onLoading(true);
      if (!curStudent?.uuid && !jwt.data.studentId.trim()) {
        uploadLog({
          event: 'missing_jwt_studentId',
          data: {
            token: Cookie.get('token'),
            jwt,
          },
        }).then(() => console.log('point'));
        onLoading(false);
        return;
      }
      getRecentlyCourseData(curStudent?.uuid || jwt.data.studentId)
        .then((res) => {
          onLoading(false);
          const list = res || [];
          setLesson(list);
          // const params = findMinSTItem(list);
          // if (params) {
          //   // getHowClassData({
          //   //   subject: params.courseSchedule.courseSectionRespDTO.course.subject,
          //   //   platform: params.courseSchedule.platform,
          //   // }).then((howCLassRes) => {
          //   //   // setHowClasses(howCLassRes.content);
          //   // });
          // } else {
          //   // setHowClasses(null);
          // }
        })
        .catch((e) => {
          toast.error(e);
        })
        .finally(() => {
          onLoading(false);
        });
    } else {
      onLoading(false);
    }
  };

  /**
   * 首页数据
   */
  const fetchHomeData = () => {
    getHomeData().then((res) => {
      const {
        trial,
        publishHomework,
        reviewedHomeworks,
        latestTrailedSubject,
        latestAbsent,
        showParentalVerification,
        trackSubject,
        showBeforeClass,
      } = res || {};
      setMathVis(trial?.MATH ?? 'NONE');
      setChineseVis(trial?.CHINESE ?? 'NONE');
      setEnglishVis(trial?.ENGLISH ?? 'NONE');
      setHomeworkData({ publish: publishHomework, revieweds: reviewedHomeworks });
      setAbsentData(latestAbsent?.courseSchedule ?? null);
      // 用户是否有任意排课
      setIsAppointment(showParentalVerification);
      setTrainShow(latestTrailedSubject);
      setThrowSubject((router?.query?.subject as LessonType) || (trackSubject as LessonType));
      setHomeData(res);
      setIsAttendance(showBeforeClass as boolean);
    });
  };

  // GS2 约课入口数据
  const fetchExpectationsGS2Data = () => {
    getExpectationsGS2().then((res) => {
      if (res) {
        setGs20ChineseUser(res?.gs20User && res?.displayChineseInfo);
        setIsGs2User(!!res?.gs20User);
        setExpectationsGs2(res?.teacher);
        setGs2(res?.gs20User);
        setExpectationsTeachers(res?.previewTeacherImages);
      } else {
        setGs2(false);
        setIsGs2User(false);
      }
    });
  };

  const initData = () => {
    fetchExpectationsGS2Data();
    fetchHomeData();
    handleGetRecentlyCourseData();
    (async () => {
      const timezoneStr = localStorage.getItem(STUDENT_TIMEZONES);
      const timezones = timezoneStr ? (JSON.parse(timezoneStr) as string[]) : [];
      const agreeFlag = timezones?.find((item) => item === curStudent?.uuid);
      const setTimezones = (uuid: string) => {
        localStorage.setItem(STUDENT_TIMEZONES, JSON.stringify([...timezones, uuid]));
      };
      const { offset, timezone } = await getCurTimezone();
      if (!agreeFlag && curStudent && !modalRef.current) {
        modalRef.current = Modal.info({
          content: (
            <div className={styles.modalContent}>
              <div className={styles.modalTitle}>{curriculumTranslation('学员时区信息确认')}</div>
              <div className={styles.modalSubTitle}>{`${offset?.substring(
                0,
                offset?.indexOf(')') + 1,
              )} ${timezone}`}</div>
              <div
                className={styles.modalSubTip}
                dangerouslySetInnerHTML={{ __html: curriculumTranslation('确认当前学员时区提示') }}
              />
              <div className={styles.btnGroup}>
                <Button
                  onClick={() => {
                    traceEvent({
                      data: {
                        _event: 'U_TimeAreaPop_ModifyClick',
                        account_id: account?.uuid,
                        Code: curStudent?.code,
                      },
                    });
                    modalRef.current?.close();
                    setTimezones(curStudent?.uuid);
                    router.push(PATHS.profile);
                  }}
                  className={cls(styles.button, styles.cancel)}
                  color="blue"
                >
                  {curriculumTranslation('立即修改')}
                </Button>
                <Button
                  onClick={async () => {
                    traceEvent({
                      data: {
                        _event: 'U_TimeAreaPop_CorrectClick',
                        account_id: account?.uuid,
                        Code: curStudent?.code,
                      },
                    });
                    setTimezones(curStudent?.uuid);
                    modalRef.current?.close();
                    await updateProfile({
                      studentId: curStudent?.uuid,
                      timezoneStd: timezone,
                      timezone: offset,
                      fullName: curStudent?.fullName,
                    });
                    onGetStudents();
                    window.location.reload();
                  }}
                  className={cls(styles.button, styles.submit)}
                >
                  {curriculumTranslation('时区正确')}
                </Button>
              </div>
            </div>
          ),
          closeBtn: false,
          className: styles.alert,
        });
      }
    })();
    // checkAccountSafe().then(({ data }: { data: any }) => {
    //   if (data?.includes('MOBILE') || data?.includes('EMAIL')) {
    //     setAccountShow(true);
    //   }
    // });
    // 调取学习任务
    getHomeworkList(1, 'normal', {
      cancelToken: new axios.CancelToken(() => {}),
    }).then((data) => {
      const { totalCount } = data || {};
      updateNews({
        lessonTask: { show: totalCount > 0 },
      });
    });

    // 获取我的课程
    getMyCourse().then((data) => {
      setMyCourseList(data?.toReserveList || []);
    });
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (reserveFlag && !tourist) {
      initData();
    }
  }, [reserveFlag, initData]);

  const initTouristData = () => {
    // 游客模式逻辑处理
    onLoading(true);
    getVisitorData({ subject: urlParams?.subject })
      .then((res) => {
        const { recommendTips } = res || {};
        setRecommendList(recommendTips);
      })
      .finally(() => {
        onLoading(false);
      });
    setTrainShow(urlParams?.subject);
    const methodMap: SubjectMethodMap = {
      ENGLISH: setEnglishVis,
      CHINESE: setChineseVis,
      MATH: setMathVis,
    };
    methodMap[urlParams?.subject as keyof SubjectMethodMap]('COMPLETED');
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (!tourist) {
      initData();
    } else {
      initTouristData();
    }
  }, []);

  useEffect(() => {
    if (curStudent?.timezoneStd && lesson.length) {
      getPreviewCourseBtnStatus();
    }
  }, [lesson, curStudent?.timezoneStd]);

  useEffect(() => {
    const hasEnterLead = `${cacheStorage.getItem(LEAD_FLAG) || '0'}` === '1';
    setVisible(hasEnterLead);
  }, [students]);

  const setMainDomainToken = useCallback((tokenKey?: string) => {
    Cookie.set(tokenKey ?? TOKEN, cacheStorage.getItem(TOKEN), {
      domain: window.location.href.includes('edu.net') ? EDU_URL : SCH_URL,
      path: '/',
    });
  }, []);

  const joinClass = async (
    courseId: string,
    classId: string,
    stuScheduleId: string,
    courseScheduleId: string,
    platform: string,
    subject: RecentlyCourseItem['courseSchedule']['courseSectionRespDTO']['course']['subject'],
    trialStudent: boolean,
  ) => {
    if (!onBeforeClick()) {
      updateModuleName('最近课程');
      return;
    }
    log(prefixName`enter-class`);
    if (platform === 'AGORA') {
      setMainDomainToken();
      window.open(`${AGORA_URL}/${i18n.language}/student?courseScheduleId=${courseScheduleId}`);
    } else if (platform === 'TALK_CLOUD') {
      if (isTablet()) {
        wakeUpWukong();
        return;
      }
      if (forbiddenBrowser()) {
        Toast.warning(curriculumTranslation('课中直播不支持使用火狐、IE或者Opera浏览器，建议使用Chrome浏览器去上课。'));
        return;
      }
      removeStorage(i18n.language);
      // get TalkCloud url
      // 解决可能被浏览器拦截的问题
      const target = window.open('about:blank');
      const jwt = parseJWT();
      const name =
        subject === 'CHINESE'
          ? curStudent?.fullName
            ? curStudent.fullName || ''
            : curStudent?.englishName || ''
          : curStudent?.englishName
            ? curStudent.englishName || ''
            : curStudent?.fullName || '';
      getTalkCloudLink({
        courseScheduleId,
        clientType: 'WEB',
        userType: 'STUDENT',
        uuid: curStudent?.uuid || jwt.data.studentId,
        name: name?.trim() || 'Student',
        language: i18n.language === 'en' ? 'EN_US' : 'ZH_CN',
        trialStudent: subject === 'MATH' && trialStudent,
        norecord: curStudent && !curStudent.setting.playbackPrivacyStatus,
      })
        .then((e) => {
          if (e) {
            target!.location.href = e;
          } else {
            target?.close?.();
          }
        })
        .catch((error) => {
          trace('LOG_ERROR_GET_TALK_CLOUD_LINK', {
            error,
          });
          target?.close?.();
        });
    } else {
      const { data, error_info: errorInfo } = await getClassInLink({
        courseId,
        classId,
        stuScheduleId,
        uid: curStudent?.classInId ?? '',
        // eslint-disable-next-line no-nested-ternary
        deviceType: isiPad() ? DEVICE.IOS : isAndroidPad() ? DEVICE.ANDROID : DEVICE.PC,
      });
      if (data) {
        window.open(`${CLASS_IN_URL}?${data?.split('?').pop()}`);
      } else {
        Toast.warning(errorInfo?.error);
      }
    }
  };

  const handleJoinClass = async (
    courseId: string,
    classId: string,
    stuScheduleId: string,
    courseScheduleId: string,
    platform: string,
    subject: RecentlyCourseItem['courseSchedule']['courseSectionRespDTO']['course']['subject'],
    trialStudent: boolean,
  ) => {
    const toJoinClass = () => {
      joinClass(courseId, classId, stuScheduleId, courseScheduleId, platform, subject, trialStudent);
    };

    const hasUserInClass = await getHasUserInClass({
      courseScheduleId,
      studentId: curStudent?.uuid || parseJWT?.()?.data?.studentId,
    });
    if (hasUserInClass) {
      ToolTipModal.open(curriculumTranslation, toJoinClass);
      return;
    }

    toJoinClass();
  };

  const handleOpenData = async (courseScheduleId: string) => {
    if (isCompletedParentalVerification) {
      setShowVerificationModal(true);
      return;
    }
    if (!onBeforeClick()) {
      updateModuleName('最近课程');
      return;
    }
    const result = await canViewClassData(courseScheduleId);
    log(prefixName`enter-data`);
    if (result) {
      setMainDomainToken();
      const url = `${HOMEWORK_URL()}/${i18n.language}/material?courseScheduleId=${courseScheduleId}`;
      router.push({
        pathname: `/home/iexercise`,
        query: { url, backUrl: router.asPath },
      });
    } else {
      Toast.warning(t('无法进入本科重点'));
    }
  };

  const infoModalConfirm = () => {
    setInfoModalVisible(false);
  };

  const onRefresh = () => {
    if (!tourist) {
      initData();
    }
  };

  const onHistoryBack = useCallback(
    (e) => {
      if (e.persisted && !tourist) {
        initData();
      }
    },
    [initData],
  );

  useEffect(() => {
    window.addEventListener('pageshow', onHistoryBack);
    return () => {
      window.removeEventListener('pageshow', onHistoryBack);
    };
  }, [onHistoryBack]);

  const getHasTeachingMaterials = useCallback(
    (item: RecentlyCourseItem) => {
      return teachingMaterials.find((s) => s.uuid === item.uuid)?.hasTeachingMaterials || false;
    },
    [teachingMaterials],
  );

  /**
   * 跳转落地页
   *
   * @param {string} itemSubject
   */
  const toLandingPage = (itemSubject: string) => {
    const url = recommendList?.find?.(({ subject }) => subject === itemSubject)?.expandLink;
    window.location.href = `${url}&studentCode=${urlParams?.studentCode || ''}`;
  };

  const renderAppointmentBlock = () => {
    // 用户已预约了体验课 or 用户已购买课时成为付费用户时，此时不再展示约课模块
    if (!trainShow) {
      return null;
    }
    // 如果是gs20User用户 不展示
    if (gs20ChineseUser) {
      return null;
    }
    // 未预约有科目
    if (throwSubject) {
      return <Expectations subject={throwSubject?.toUpperCase() as LessonType} />;
    } else {
      // 未预约无科目 默认中文
      return <Expectations subject="CHINESE" />;
      // return <Grades />;
    }
  };

  const renderAppointmentBlockGs20User = () => {
    // 用户已预约了体验课 or 用户已购买课时成为付费用户时，此时不再展示约课模块
    // 如果 是gs20User用户 展示
    if (!trainShow) {
      return null;
    }
    if (gs20ChineseUser) {
      return <ExpectationsGS2 teacher={expectationsGs2} subject="chinese" teachersHead={expectationsTeachers} />;
    } else {
      return null;
    }
  };
  /* 家长验证： 未完成家长验证 and 学员有约课 - 显示 */
  const renderParentVerification = () => {
    if (isAppointment) {
      return <ParentVerification />;
    }
    return null;
  };

  /* 开始-顶部模块与底部模块展示逻辑 */
  const observerWrapper = (node: HTMLElement) => {
    if (!node) return;

    node.style.display = node.childNodes?.length ? 'block' : 'none';

    const observer = new MutationObserver(() => {
      const hasChildren = node.childNodes.length > 0;
      node.style.display = hasChildren ? 'block' : 'none';
    });

    observer.observe(node, { childList: true });

    return observer;
  };

  useEffect(() => {
    const topWrapperObserver = observerWrapper(topWrapperRef.current);
    const bottomWrapperObserver = observerWrapper(bottomWrapperRef.current);

    return () => {
      topWrapperObserver?.disconnect?.();
      bottomWrapperObserver?.disconnect?.();
    };
  }, []);
  /* 结束-顶部模块与底部模块展示逻辑 */

  return (
    <div className={styles.home} style={{ display: isWrapperLoading ? 'none' : 'block' }}>
      {visible && (
        <>
          <Mask />
          <div className={styles.modalBody}>
            <img
              src={
                i18n?.language === 'zh' ? '/imgs/pc/family-modal-banner-zh.svg' : '/imgs/pc/family-modal-banner-en.svg'
              }
              alt=""
            />
            <div className={styles.modalBodyText}>{loginTranslate('领取体验课提示')}</div>
            <div
              className={styles.modalBodyBtn}
              role="button"
              onClick={() => {
                setVisible(false);
                cacheStorage.removeItem(LEAD_FLAG);
              }}
            >
              {loginTranslate('知道了')}
            </div>
          </div>
        </>
      )}
      <div className={cls(styles.wrapper, 'hidden')} ref={topWrapperRef}>
        {/* 置顶通知 */}
        {!tourist && <Notice />}
        {/* 推荐订阅 */}
        {!stuScheduleContent?.display && !!Object.keys(curStudent || {})?.length && !curStudent?.gs20User && (
          <SubscriptionCourse />
        )}
        {/* es 2.0 订阅入口 */}
        {!!stuScheduleContent?.display && (
          <SubscriptionEs
            isGs2={curStudent?.gs20User}
            stuScheduleContent={stuScheduleContent}
            curStudentUid={curStudent?.uuid}
          />
        )}
        {/* 家长验证 */}
        {renderParentVerification()}
        {/* 约课提示 */}
        {renderAppointmentBlock()}
      </div>
      <TrialCourse />
      {renderAppointmentBlockGs20User()}
      <div className={cls(styles.wrapper, 'hidden')} ref={bottomWrapperRef}>
        {/* 学情中心 */}
        {!!learnSituationData?.length && <LearnSituation data={learnSituationData} />}
        {/* 课程回顾-出勤 */}
        {homeData?.crmReport && curStudent && !lesson.length && (
          <LessonTest curStudent={curStudent} crmReport={homeData.crmReport} onBeforeClick={onBeforeClick} />
        )}
        {/* 课程回顾-缺勤 */}
        {absentData && <HomeAbsent {...absentData} onBeforeClick={onBeforeClick} />}
        {/* 最新作业 */}
        {(homeworkData?.publish || homeworkData?.revieweds?.length) && (
          <NewHomework {...homeworkData} isAttendance={isAttendance} />
        )}
        {/* 最近课程 */}
        {lesson.length > 0 && (
          <>
            <div className={cls(styles.title, styles.boldTitle)}>{t('最近课程')}</div>
            <div className={styles.lectures}>
              {lesson.map((item, index) => {
                const {
                  courseSchedule,
                  courseId,
                  uuid,
                  qwImageUrl,
                  canAdjustTime,
                  canMonitor,
                  status,
                  canLeave,
                  trial,
                } = item;
                const {
                  startDateTime,
                  endDateTime,
                  timeOffset,
                  courseSectionRespDTO,
                  duration,
                  classType,
                  teacher,
                  classInId,
                  platform,
                  uuid: courseScheduleId,
                  audienceLink,
                } = courseSchedule;
                const { course, stageDisplay, sectionNameDisplay, sectionNameDisplayEn } = courseSectionRespDTO;
                return (
                  <Lecture
                    account={account}
                    curStudent={curStudent}
                    key={uuid}
                    isLast={index === lesson.length - 1}
                    isFirst={index === 0}
                    isAttendance={isAttendance}
                    canAdjustTime={canAdjustTime}
                    canMonitor={canMonitor}
                    className={styles.lectureItem}
                    timeOffset={timeOffset}
                    subject={course.subject as SubjectTypeEnum}
                    unitTitle={stageDisplay}
                    title={i18n?.language === 'zh' ? sectionNameDisplay : sectionNameDisplayEn}
                    classType={classType as ClassTypeEnum}
                    classTypeName={t(getClassTypeName(classType))}
                    duration={`${duration} ${t('分钟')}`}
                    platform={platform}
                    courseScheduleId={courseScheduleId}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    teacher={{
                      name: teacher.fullName,
                      avatar: teacher?.data?.wkHeadImageUrl || teacher?.data?.contentUrl || '/imgs/default-teacher.png',
                    }}
                    onEnterClass={() => {
                      handleJoinClass(courseId, classInId, uuid, courseScheduleId, platform, course.subject, trial);
                    }}
                    onEnterData={() => handleOpenData(courseScheduleId)}
                    hlsUrl={audienceLink}
                    hasTeachingMaterials={getHasTeachingMaterials(item)}
                    courseType={course?.courseType}
                    qrUrl={qwImageUrl}
                    uuid={uuid}
                    status={status}
                    showLeaveButton={canLeave}
                    onRefresh={onRefresh}
                    onBeforeClick={onBeforeClick}
                    trialStudent={trial}
                  />
                );
              })}
            </div>
          </>
        )}
        {/* 我的课程 */}
        {myCourseList?.length > 0 && (
          <>
            <div className={cls(styles.title, styles.boldTitle, styles.myCourseTitle)}>
              <span style={{ color: '#1b1f4d' }}>{t('我的课程')}</span>
              {/* <Link href="/my-course">{t('更多课程')}</Link> */}
            </div>
            <div className={styles.lectures}>
              {myCourseList.map((course) => (
                <MyLecture key={course.uuid} {...course} />
              ))}
            </div>
          </>
        )}
        {/* 错题本 */}
        {!tourist && <WrongQuestion uuid={curStudent?.uuid} />}
        {/* 为你推荐 */}
        {!/^\+86/.test(account?.phone || '') &&
          (mathVis !== 'COMPLETED' || chineseVis !== 'COMPLETED' || englishVis !== 'COMPLETED') &&
          gs2 === false && (
            <>
              <div className={cls(styles.title, styles.boldTitle)}>{t('预约体验课')}</div>
              <div className={styles.subscribe}>
                {chineseVis !== 'COMPLETED' && (
                  <Subscribe
                    cover="/imgs/pc/home-subscribe-bg-chinese.png"
                    className={cls(styles.subscribeItem, styles.subscribeItemChinese)}
                    onClick={async () => {
                      // if (!onBeforeClick()) {
                      //   updateModuleName('为你推荐');
                      //   return;
                      // }
                      log(prefixName`chineseTrain`);
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      students?.length === 0 && log(prefixName`clickChineseTrain`);
                      if (tourist) {
                        toLandingPage('CHINESE');
                      } else if (chineseVis === 'TRAILED') {
                        Toast.warning(t('您已预约过该体验课'));
                      } else if (chineseVis === 'NONE') {
                        // router.push('/home/chinese');
                        traceEvent({
                          data: {
                            _event: `U_CourseCard_BookingClick`,
                            account_id: account?.uuid,
                            subject: 'CHINESE',
                            coursecard_name: t('为你推荐-中文课-标题'),
                          },
                        });
                        traceEvent({
                          data: {
                            _event: 'U_HP_AvailableClassesCard_GetNowClick',
                            subject: 'CHINESE',
                          },
                        });
                        // router.push(
                        //   `/home/independent-appointment?subject=chinese&booking_triggerevent=U_CourseCard_BookingClick`,
                        // );
                        goWebSite(
                          `independent-appointment?subject=chinese&booking_triggerevent=U_CourseCard_BookingClick`,
                        );
                      } else if ((curStudent?.owners ?? []).length > 0) {
                        router.push('/home/result?status=exist&type=chinese');
                      }
                    }}
                    submitText={t(chineseVis === 'TRAILED' ? '已预约' : '立即预约')}
                    title={t('免费体验课')}
                    type="chinese"
                    btnColor={chineseVis === 'TRAILED' ? 'gray' : 'yellow'}
                    typeAppointment={chineseVis}
                    bannerTitle={t('为你推荐-中文课-标题')}
                    bannerSubTitle={t('为你推荐-中文课-副标题')}
                    subject="CHINESE"
                    size={subscribeSize}
                  />
                )}
                {mathVis !== 'COMPLETED' && (
                  <Subscribe
                    cover="/imgs/pc/home-subscribe-bg-math.png"
                    className={cls(styles.subscribeItem, styles.subscribeItemMath)}
                    onClick={async () => {
                      // if (!onBeforeClick()) {
                      //   updateModuleName('为你推荐');
                      //   return;
                      // }
                      log(prefixName`mathTrain`);
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      students?.length === 0 && log(prefixName`clickMathTrain`);
                      if (tourist) {
                        toLandingPage('MATH');
                      } else if (mathVis === 'TRAILED') {
                        Toast.warning(t('您已预约过该体验课'));
                      } else if (mathVis === 'NONE') {
                        // router.push('/home/math');
                        traceEvent({
                          data: {
                            _event: `U_CourseCard_BookingClick`,
                            account_id: account?.uuid,
                            subject: 'MATH',
                            coursecard_name: t('为你推荐-数学课-标题'),
                          },
                        });
                        traceEvent({
                          data: {
                            _event: 'U_HP_AvailableClassesCard_GetNowClick',
                            subject: 'MATH',
                          },
                        });
                        // router.push(
                        //   '/home/independent-appointment?subject=math&booking_triggerevent=U_CourseCard_BookingClick',
                        // );
                        goWebSite(
                          `independent-appointment?subject=math&booking_triggerevent=U_CourseCard_BookingClick`,
                        );
                      } else if ((curStudent?.owners ?? []).length > 0) {
                        router.push('/home/result?status=exist&type=math');
                      }
                    }}
                    submitText={t(mathVis === 'TRAILED' ? '已预约' : '立即预约')}
                    title={t('免费体验课')}
                    type="math"
                    btnColor={mathVis === 'TRAILED' ? 'gray' : 'yellow'}
                    typeAppointment={mathVis}
                    bannerTitle={t('为你推荐-数学课-标题')}
                    bannerSubTitle={t('为你推荐-数学课-副标题')}
                    subject="MATH"
                    size={subscribeSize}
                  />
                )}
                {englishVis !== 'COMPLETED' && (
                  <Subscribe
                    cover="/imgs/pc/home-subscribe-bg-english.png"
                    className={cls(styles.subscribeItem, styles.subscribeItemEnglish)}
                    onClick={async () => {
                      log(prefixName`englishTrain`);
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      students?.length === 0 && log(prefixName`clickEnglishTrain`);
                      if (tourist) {
                        toLandingPage('ENGLISH');
                      } else if (englishVis === 'NONE') {
                        // router.push(`${PATHS.trainOrder}?type=english`);
                        traceEvent({
                          data: {
                            _event: `U_CourseCard_BookingClick`,
                            account_id: account?.uuid,
                            subject: 'ENGLISH',
                            coursecard_name: t('为你推荐-英语课-标题'),
                          },
                        });
                        traceEvent({
                          data: {
                            _event: 'U_HP_AvailableClassesCard_GetNowClick',
                            subject: 'ENGLISH',
                          },
                        });
                        // router.push(
                        //   '/home/independent-appointment?subject=english&booking_triggerevent=U_CourseCard_BookingClick',
                        // );
                        goWebSite(
                          `independent-appointment?subject=english&booking_triggerevent=U_CourseCard_BookingClick`,
                        );
                      } else if (englishVis === 'TRAILED') {
                        Toast.warning(t('您已预约过该体验课'));
                      }
                    }}
                    submitText={t(englishVis === 'TRAILED' ? '已预约' : '立即预约')}
                    title={t('免费体验课')}
                    type="english"
                    btnColor={englishVis === 'TRAILED' ? 'gray' : 'yellow'}
                    typeAppointment={englishVis}
                    bannerTitle={t('为你推荐-英语课-标题')}
                    bannerSubTitle={t('为你推荐-英语课-副标题')}
                    subject="ENGLISH"
                    size={subscribeSize}
                  />
                )}
              </div>
            </>
          )}
      </div>
      {infoModalVisible && (
        <InfoModal
          showBer
          visible={infoModalVisible}
          title={t('首页-弹窗-联系顾问-标题')}
          showClose={false}
          showCancel={false}
          okText={t('首页-弹窗-联系顾问-按钮-知道了')}
          onConfirm={infoModalConfirm}
        >
          {t('首页-弹窗-联系顾问-内容')}
        </InfoModal>
      )}
      {(homeData?.mobile || urlParams?.mobile) && (
        <VerifyModalDynamic
          visible={showVerCode}
          phone={homeData?.mobile || `${urlParams?.countryCode} ${urlParams?.mobile}`}
          onClose={memorizedHandleShowVerCodeChange}
          subject={urlParams?.subject}
        />
      )}
      <ParentalVerification
        open={showVerificationModal}
        onClose={() => {
          setShowVerificationModal(false);
        }}
      />
    </div>
  );
};

export default Home;

Home.getLayout = (page: React.ReactElement) => (
  <Layout enableCenterMode isProcessingLoading canSwitchStudent>
    {page}
  </Layout>
);
